body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.str-chat {
  height: 88vh !important;
}

.str-chat.messaging,
.str-chat.commerce,
.str-chat__channel-list-messenger {
  background-color: #13223c !important;
}

.str-chat-channel.messaging .str-chat__main-panel {
  padding-bottom: 15px !important;
}

.str-chat__channel-preview-messenger {
  color: #eae8e5 !important;
}

.str-chat__channel-preview-messenger--active {
  color: #3a3b3c !important;
}
